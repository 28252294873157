import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';

import FaqHero from '../components/FaqHero';

import Faq from '../components/Faq';

const FaqWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .center{
        width: 90%;
        display: flex;
        justify-content: center;
    }

    button {
        margin: 5%;
        padding-left: 25px;
        padding-right: 25px;
    }
`

export default function FAQPage({ data })
{
    return(
        <>
            <SEO 
                title="FAQ"
                description="Read up on our informative articles cover everything cananbis! From strain recommendations to guides for beginners we are ready to educate you!"
            />

            <FaqHero/>

            <FaqWrapper>
                <div className="center">
                    <Faq faqs={data.faqs}/>
                </div>

                <button>
                    <Link to="/locations">ORDER ONLINE NOW</Link>
                </button>
            </FaqWrapper>
        </>
    )
}

export const query = graphql`
    query{
        faqs: allSanityFaq(filter: { page: { eq: "FAQ Page" } }) {
            nodes {
                id
                question
                answer
                page
            }
        }
    }
`;