import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const Wrapper = styled.div`
    h1{
        color: white;
        text-align: center;
        position: relative;
        z-index: 3;
    }
    .p-0{
        padding: 0px;
    }
    .brush{
        position: relative;
        z-index: 2;
        margin-top: -55px;
        margin-bottom: -20px;
        width: 100%;
    }
    .banner-image{
        width: 100%;
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15vh 50px;
        padding-top: 20vh;
    }

    @media (max-width: 1000px){
        h1{
            font-size: 62px;
        }
        .banner-image,
        .banner-image::before,
        .banner-image::after {
            background-position-y: center;
            background-position-x: 37.5%;
            padding-top: 12vh;
            height: 75vh;
        }

    }
`

export default function FaqHero()
{
    // ----Gatsby Background Image Set-Up-----
    const { bannerImage } = useStaticQuery(
        graphql`
        query {
            bannerImage: file(relativePath: { eq: "faq_page_background.png" }) {
            childImageSharp {
                gatsbyImageData(
                width: 1000
                quality: 90
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                )
            }
            }
        }
        `
    );
    const image = getImage(bannerImage);
    const bgImage = convertToBgImage(image);

    return(
        <Wrapper>
            <BackgroundImage
                Tag="div"
                {...bgImage}
                preserveStackingContext
                className="banner-image"
                style={{
                    backgroundPosition: '',
                }}
            >
                <h1>FAQ's</h1>
            </BackgroundImage>
            <img src="/svg/BrushStroke.svg" className="brush"/>
        </Wrapper>
    )

}
